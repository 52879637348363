export const environment = {
  production: true,
  devServer: true,
  uatServer: false,
  prodServer: false,
  local: false,
  hippoRootProto: 'https',
  hippoRootUrl: 'content.dev.gms.com',
  hippoCmsRootUrl: 'cms.dev.gms.com',
  hippoSpaRootUrl: 'dev.gms.com',
  hippoRootPort: '443',
  hippoSitePrefix: '',
  endpoint: 'https://content.dev.gms.com/resourceapi',
  cms: 'https://cms.dev.gms.com',
  apiManEnpoint: 'https://content.dev.gms.com/api-man',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
import 'zone.js/dist/zone-error';  // Included with Angular CLI.
